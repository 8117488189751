import React from 'react';
import { func, string } from 'prop-types';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseSub = loadable(() => import('components/landing-prices/select/base-sub'));

const model = {
  cost: '£2:49',
  name: 'Bronze',
};

const BronzeSubscriptionSelect = ({ onClick = () => {}, sub = '' }) => {
  const styles = useStyles();

  const { name, cost } = model;

  return (
      <BaseSub
        title={`${name} ${cost} a month`}
        subheader="500 active cards"
        avatar={
          <FontAwesomeIcon icon={faStars} className={styles.bronzeAvatar}/>
          }
        name={name}
        onClick={async () => {
          const buildDataLayer = await import('data-layer');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'toggle_subcription_information:registration',
            label: name,
          });
          onClick(name.toLowerCase());
        }}
        active={sub === 'bronze'}
      />);
};

BronzeSubscriptionSelect.propTypes = { onClick: func, sub: string };

export default BronzeSubscriptionSelect;
